import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { Link as LinkWithLocale, withIntl } from '../i18n'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <h2 className="is-size-2">{post.node.frontmatter.articleTitle}</h2>
        </Link>
      </li>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    let locale = this.props.pageContext.locale
    let tagHeader = `${totalCount} artik${
      totalCount === 1 ? 'kel' : 'ler'
    } har nøkkelordet “${tag}”`
    if (locale == 'en') {
      tagHeader = `${totalCount} article${
        totalCount === 1 ? '' : 's'
      } is tagged with “${tag}”`
    }
    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
                <ul className="taglist">{postLinks}</ul>
                <p>
                  <LinkWithLocale className="button is-primary" to="/tags/">
                    {locale == 'no' ? 'Se alle nøkkelord' : 'See all tags'}
                  </LinkWithLocale>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

TagRoute.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object,
}

export default withIntl(TagRoute)

export const tagPageQuery = graphql`
  query TagPage($tag: String, $locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, i18n: { eq: $locale } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            articleTitle
          }
        }
      }
    }
  }
`
